import React from 'react';
import cn from 'classnames';
import { WarrantyItem } from '~/common/models';
import styles from './index.module.scss';

interface WarrantiesProps {
  warrantyTitle: string;
  warranties: WarrantyItem[];
  backgroundColor: string;
  fontColor: string;
}
export const Warranties = React.memo<WarrantiesProps>((props) => {
  return (
    <div
      className={styles.warranties}
      style={{
        color: `#${props.fontColor}`,
        backgroundColor: `#${props.backgroundColor}`,
      }}
    >
      <div className={styles.warrantiesWrapper}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: props.warrantyTitle }}
        />
        <div className={styles.warrantyList}>
          {props.warranties?.map((warranty, index) => (
            <div
              key={index}
              className={cn(styles.warrantyItemWrapper, {
                [styles.hideOnMobile]: warranty.warrantyHideOnMobile,
              })}
            >
              {index > 0 && (
                <div
                  className={cn(styles.warrantyDivider, {
                    [styles.hideOnMobile]: warranty.warrantyHideOnMobile,
                  })}
                />
              )}

              <div className={styles.warrantyItem}>
                <img
                  src={warranty.warrantyImageIcon}
                  alt={warranty.warrantyDesktopTitle}
                />
                <div
                  className={styles.desktopTitle}
                  dangerouslySetInnerHTML={{
                    __html: warranty.warrantyDesktopTitle,
                  }}
                />
                <div
                  className={styles.mobileTitle}
                  dangerouslySetInnerHTML={{
                    __html: warranty.warrantyMobileTitle,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
